export const ROUTES = {
  APP: "/",
  CENTRAL_BANK: "/central_bank",
  SUBSIDIARY_BANK: "/subsidiary_bank",
  CONVERSION_ECAD: "e-cad-conversion",
  REDEEM: "redeem",
  OTHER_CBDC: "cbdc",
  ABOUT: "/about",
  HOME: "/home",
  LOGIN: "/login",
  TRANSFER: "transfer",
};
